import React from 'react'

// Components
import TextWithImageDefault from 'components/flex/TextWithImage/TextWithImageDefault'
import TextWithImageDefaultLeft from 'components/flex/TextWithImage/TextWithImageDefaultLeft'
import TextWithImageLeft from 'components/flex/TextWithImage/TextWithImageLeft'
import TextWithImageTopLeft from 'components/flex/TextWithImage/TextWithImageTopLeft'

interface TextWithImageShellProps {
  fields: any
  location?: any
}

interface TextWithImageProps {
  [key: string]: any
}

const TextWithImageShell: React.FC<TextWithImageShellProps> = ({
  fields,
  location = {},
}) => {
  const textwithimage: TextWithImageProps = {
    default: TextWithImageDefault,
    defaultleft: TextWithImageDefaultLeft,
    left: TextWithImageLeft,
    topleft: TextWithImageTopLeft,
  }

  if (!fields.styleType || !textwithimage[fields.styleType]) {
    return null
  }

  const Component = textwithimage[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextWithImageShell
