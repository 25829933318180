import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonOutline from 'components/elements/ButtonOutline'

// Components
import Image from 'components/shared/ImageGatsby'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  background-color: #e8e3da;
  border-top-right-radius: 50px;
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size['30']};
  font-weight: 700;
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const Content = styled(ParseContent)`
  & p {
    font-size: ${(props) => props.theme.font.size.base};
    line-height: 30px;
  }
`

const StyledImage = styled(Image)`
  min-height: 550px;

  @media (max-width: 991px) {
    height: 350px;
  }
`

const Rectangle = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  width: 157px;
  height: 15px;
  transform: rotate(90deg);
  position: absolute;
  left: -4.85rem;
  z-index: 1;
  bottom: 4.5rem;
`

interface TextWithImageDefaultLeftProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const TextWithImageDefaultLeft: React.FC<TextWithImageDefaultLeftProps> = ({
  fields,
}) => (
  <section id={fields?.anchorId || ''}>>
    <div className="container py-4">
      <div className="row no-gutters py-lg-5">
        {fields?.image && (
          <div className="col-md-5 col-sm-7 flex-grow-1 d-flex mt-5 mt-sm-0">
            <StyledImage image={fields?.image} alt="Lofthus" loading="lazy" />
          </div>
        )}
        <ContentWrapper className="col-md-7 col-sm-5">
          <div className="pt-lg-5 pb-lg-5 pb-3 px-lg-5 px-3 pt-3">
            <Title>{fields?.title}</Title>
            <Content content={fields.description} />
          </div>
          {fields?.link && (
            <div className="px-lg-5 px-3 pb-3 pb-lg-5">
              <ButtonOutline
                to={fields?.link?.url || ''}
                className="text-center"
              >
                {fields?.link?.title}
              </ButtonOutline>
            </div>
          )}
          <Rectangle />
        </ContentWrapper>
      </div>
    </div>
  </section>
)

export default TextWithImageDefaultLeft
