import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'
import styled from 'styled-components'
import ButtonOutline from 'components/elements/ButtonOutline'

const ContentWrapper = styled.div`
  background-color: #e8e3da;
  border-top-left-radius: 50px;
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size['30']};
  font-weight: 700;
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const Content = styled(ParseContent)`
  & p {
    font-size: ${(props) => props.theme.font.size.base};
    line-height: 30px;
  }
`

const StyledImage = styled(Image)`
  @media (min-width: 992px) {
    min-height: 550px;
  }

  @media (max-width: 991px) {
    height: 350px;
  }
`

const Rectangle = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  width: 157px;
  height: 15px;
  transform: rotate(90deg);
  position: absolute;
  right: -4.85rem;
  z-index: 1;
  bottom: 4.5rem;
`

interface TextWithImageDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const TextWithImageDefault: React.FC<TextWithImageDefaultProps> = ({
  fields,
}) => (
  <section id={fields?.anchorId || ''}>
    <div className="container py-5">
      <div className="row no-gutters py-lg-5">
        <ContentWrapper className="col-md-7 py-5 px-4 px-lg-5 d-flex flex-column justify-content-between">
          <div className="px-lg-3 py-lg-5">
            <Title>{fields?.title}</Title>
            <Content content={fields.description} />
          </div>
          {fields?.link && (
            <div className="px-lg-3 pb-lg-5 mt-3 mt-md-0">
              <ButtonOutline
                to={fields?.link?.url || ''}
                className="text-center"
              >
                {fields?.link?.title}
              </ButtonOutline>
            </div>
          )}
          <Rectangle />
        </ContentWrapper>
        {fields?.image && (
          <div className="col-md-5 flex-grow-1 d-flex mt-0">
            <StyledImage image={fields?.image} alt="Lofthus" loading="lazy" />
          </div>
        )}
      </div>
    </div>
  </section>
)

export default TextWithImageDefault
