import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'
import ButtonOutline from 'components/elements/ButtonOutline'

const Wrapper = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 14rem;
  }
`

const StyledImage = styled(Image)`
  max-width: 920px;

  @media (min-width: 992px) {
    height: 550px;
  }
  @media (max-width: 767px) {
    max-height: 350px;
    object-fit: cover;
  }
`

const CustomCol = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    z-index: 2;
    background: ${(props) => props.theme.color.face.light};
    right: 0;
    bottom: -100px;
    min-width: 500px;
    min-height: 200px;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
    border-top-right-radius: 50px;
  }
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size['21']};
`

const Content = styled(ParseContent)``

interface TextWithImageTopLeftProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const TextWithImageTopLeft: React.FC<TextWithImageTopLeftProps> = ({
  fields,
}) => (
  <Wrapper
    className="container position-relative pb-5"
    id={fields?.anchorId || ''}
  >
    <div className="row">
      <div className="col-xl-9 col-md-6 col-12">
        <StyledImage image={fields?.image} alt="Lofthus" loading="lazy" />
      </div>
      <CustomCol className="col-xl-3 col-md-6 col-12 w-50">
        <div className="px-0 px-lg-4 py-4">
          <Title>{fields?.title}</Title>
          <Content content={fields?.description} />
          {fields?.link && (
            <div className="mt-3">
              <ButtonOutline
                to={fields?.link?.url || ''}
                className="text-center"
              >
                {fields?.link?.title}
              </ButtonOutline>
            </div>
          )}
        </div>
      </CustomCol>
    </div>
  </Wrapper>
)

export default TextWithImageTopLeft
