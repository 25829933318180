import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Image from 'components/shared/ImageGatsby'
import styled from 'styled-components'
import ButtonOutline from 'components/elements/ButtonOutline'

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.mainLight};

  & h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['30']};
    font-weight: ${(props) => props.theme.font.weight.s};
    color: ${(props) => props.theme.color.text.dark};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['21']};
  }

  & a.text-link {
    color: #eb6209;
    text-decoration: underline;
  }
`

const StyledImage = styled(Image)`
  max-width: 350px;
  @media (max-width: 991px) {
    max-width: 250px;
  }
  @media (max-width: 575px) {
    max-width: 110%;
    min-width: 110% !important;
    margin-left: -20px;
    margin-top: -415px;
    opacity: 0.1;
  }
`

interface TextWithImageLeftProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImage
}

const TextWithImageLeft: React.FC<TextWithImageLeftProps> = ({ fields }) => (
  <Wrapper className="py-lg-5 py-1" id="package">
    <div className="container px-5 py-5 px-lg-0">
      <div className="row">
        {fields?.image && (
          <div className="col-sm-4 order-2 order-sm-1 mt-4 mt-lg-0 d-flex justify-content-center">
            <div className="w-100">
              <StyledImage
                image={fields?.image}
                alt="Lofthus"
                className="w-100"
                loading="lazy"
              />
            </div>
          </div>
        )}

        <div
          className={`${
            fields.image === null ? 'p-0' : 'px-lg-5 px-0'
          } col-sm-8 order-1 order-sm-2`}
        >
          <h2 className="mb-4 text-left">{fields?.title}</h2>
          <Content content={fields.description} />
          {fields?.link && (
            <div className="mt-4">
              <ButtonOutline to={fields?.link?.url || ''}>
                {fields?.link?.title}
              </ButtonOutline>
            </div>
          )}
        </div>
      </div>
    </div>
  </Wrapper>
)

export default TextWithImageLeft
